<template>
  <div class="center">
    <v-container>
      <v-row>
        <v-col class="d-flex justify-center">
          <div class="activity_rounded">
            <img
              src="https://i.imgur.com/GDnjGAp.jpg"
              :width="avatarSize"
              :height="avatarSize"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center">
          <div :class="titleClass">
            Philipp<span class="font-italic font-weight-light">"Flip"</span>
            Katzenberger
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center">
          <div :class="subTitleClass">
            Hobby Full-Stack Developer & Automotive Photographer
          </div>
        </v-col>
      </v-row>
      <v-row class="hidden-xs-only">
        <v-col class="d-flex justify-end">
          <v-btn color="primary" to="/web-development-portfolio"
            >Web-Development</v-btn
          >
        </v-col>
        <v-col>
          <v-btn href="https://m8motion.com" color="primary" target="_blank">
            Photography</v-btn
          >
        </v-col>
      </v-row>
      <v-row class="hidden-sm-and-up">
        <v-col class="d-flex justify-center">
          <v-btn color="primary" to="/web-development-portfolio"
            >Web-Development</v-btn
          >
        </v-col>
      </v-row>
      <v-row class="hidden-sm-and-up">
        <v-col class="d-flex justify-center">
          <v-btn href="https://m8motion.com" color="primary" target="_blank">
            Photography</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "HomeView",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Home",
      meta: [
        {
          name: "description",
          content: "Home of FantasyFlip.de",
        },
      ],
    };
  },
  computed: {
    titleClass: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-h5  font-weight-bold";
        case "sm":
          return "text-h4 font-weight-bold";
        case "md":
          return "text-h3 font-weight-bold";
        case "lg":
          return "text-h3 font-weight-bold";
        case "xl":
          return "text-h3 font-weight-bold";
        default:
          return "text-h3 font-weight-bold";
      }
    },
    avatarSize: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "200";
        case "sm":
          return "250";
        case "md":
          return "300";
        case "lg":
          return "300";
        case "xl":
          return "300";
        default:
          return "300";
      }
    },
    subTitleClass: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-body-2  primary--text text-center";
        case "sm":
          return "primary--text";
        case "md":
          return "primary--text";
        case "lg":
          return "primary--text";
        case "xl":
          return "primary--text";
        default:
          return "tprimary--text";
      }
    },
  },
};
</script>
<style scoped>
.activity_rounded {
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -khtml-border-radius: 50%;
  border: 4px solid var(--v-primary-base);
}

.activity_rounded img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -khtml-border-radius: 50%;
  vertical-align: middle;
}
.center {
  height: 80vh;
  display: grid;
  place-items: center;
}
</style>
