<template>
  <v-card class="outline-3">
    <v-container>
      <v-row>
        <v-col cols="2" class="d-flex justify-center align-center">
          <v-icon large color="primary" class="mr-n4">{{ icon }}</v-icon>
        </v-col>
        <v-col>
          <v-container>
            <v-row>
              <v-col class="d-flex align-center">
                <div class="text-h6 primary--text pr-4">{{ title }}</div>
                <v-btn color="primary" icon :href="link" target="_blank">
                  <v-icon small> mdi-open-in-new </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <div class="text-subtitle-2">{{ genre }}</div>
              </v-col>
            </v-row>
            <v-row class="mt-n1">
              <v-col>
                <div>
                  {{ description }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
export default {
  name: "projectShowcase",
  props: {
    title: String,
    description: String,
    link: String,
    icon: String,
    genre: String,
  },
};
</script>
<style scoped>
/* outline 6 px */
.outline-3 {
  /* outline: 3px solid var(--v-primary-base);
  border-radius: 10px; */
  display: inline-block;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  border-radius: 0.25rem;
  -khtml-border-radius: 0.25rem;
  border: 3px solid var(--v-primary-base);
}
</style>
