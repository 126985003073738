<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="3" class="hidden-sm-and-down"></v-col>
        <v-col md="6" class="d-flex justify-center">
          <span :class="titleClass">Web-Development Portfolio</span>
        </v-col>
        <v-col class="hidden-sm-and-down"></v-col>
      </v-row>
      <v-row v-for="project in projects" :key="project.link">
        <v-col cols="3" class="hidden-sm-and-down"></v-col>
        <v-col md="6">
          <projectShowcase
            :title="project.title"
            :description="project.description"
            :icon="project.icon"
            :link="project.link"
            :genre="project.genre"
          />
        </v-col>
        <v-col class="hidden-sm-and-down"></v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import projectShowcase from "../components/projectShowcase.vue";
export default {
  name: "webDevelopmentPortfolio",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Web-Development Portfolio",
      meta: [
        {
          name: "description",
          content: "Web-Development Portfolio of FantasyFlip.de",
        },
      ],
    };
  },
  components: {
    projectShowcase,
  },
  computed: {
    titleClass: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-h5 primary--text font-weight-bold";
        case "sm":
          return "text-h4 primary--text font-weight-bold";
        case "md":
          return "text-h4 primary--text font-weight-bold";
        case "lg":
          return "text-h4 primary--text font-weight-bold";
        case "xl":
          return "text-h4 primary--text font-weight-bold";
        default:
          return "text-h4 primary--text font-weight-bold";
      }
    },
  },
  data() {
    return {
      projects: [
        {
          title: "Just 4 Regular Guy Photography",
          genre: "Photography Portfolio",
          icon: "mdi-camera-iris",
          link: "https://j4rg.m8motion.com/",
          description:
            "A photography portfolio website for a photographer who is just starting out. I used Vue.js, Vuetify and Vue Router for this Project. Combined with a Supabase Database and a custom CMS, powered by DigitalOcean Spaces, this website is a great way to showcase my photography partners work.",
        },
        {
          title: "M8Motion",
          genre: "Photography Portfolio",
          icon: "mdi-car",
          link: "https://m8motion.com/",
          description:
            "A photography portfolio website for the automotive photography I produce together with my partner from J4RG Photography. I used Vue.js, Vuetify and Vue Router for the Front-End that is powered by a Supabase Database and a Cloudflare Worker. Also used is a custom CMS powered by DigitalOcean Spaces.",
        },
        {
          title: "Fuel Tracker",
          genre: "Web Application",
          icon: "mdi-fuel",
          link: "https://fuel-tracker.com/",
          description:
            "A web application that allows you to track your fuel consumption for multiple cars and alway find the cheapest gas station in surrounding (Available in Germany). I used Vue.js, Vuetify and Vue Router for the Front-End. Powered by a strong Cloudflare Worker hosted API fetching Data from tankerkoenig.de.",
        },
        {
          title: "Listify - Spotify Playlist Manager",
          genre: "Web Application",
          icon: "mdi-spotify",
          link: "https://listify.live/",
          description:
            "A web application that provides a smart way to manage your Spotify Playlists. I used Vue.js, Vuetify and Vue Router for the Front-End. Powered by a strong Cloudflare Worker hosted API fetching Data from Spotify and processing the provided data.",
        },
        {
          title: "Guess Game",
          genre: "Browser Game",
          icon: "mdi-gamepad-variant",
          link: "https://playguess.games/",
          description:
            "A browser game to guess on provided questions against your friends online. I used Vue.js, Vuetify and Vue Router for the Front-End and a Cloudflare Worker for basic Data fetching and a Node-Socket Server for processing game data, hosted on Heroku.",
        },
        {
          title: "DZ60 PCB Firmware ISO",
          genre: "Keyboard Firmware",
          icon: "mdi-keyboard-variant",
          link: "https://github.com/fantasyflip/DZ60-ISO",
          description:
            "A firmware for custom keyboard using a DZ60 PCB with an ISO-Layout. It provides multiple layers for changing the underglow and using function keys. The Firmware is coded in C.",
        },
      ],
    };
  },
};
</script>
<style scoped></style>
