import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/HomeView.vue";
import WebDevelopmentPortfolio from "../views/WebDevelopmentPortfolio.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/web-development-portfolio",
    name: "WebDevelopmentPortfolio",
    component: WebDevelopmentPortfolio,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
