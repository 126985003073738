<template>
  <v-app-bar color="background" elevate-on-scroll fixed>
    <v-row>
      <v-col class="d-flex align-center">
        <div
          :class="titleClass"
          @click="$router.push({ name: 'Home' })"
          style="cursor: pointer"
        >
          FantasyFlip
          <span class="primary--text ml-n2">.</span>&nbsp;
        </div>
      </v-col>
      <v-col cols="4" class="d-flex justify-end pa-2">
        <v-btn
          icon
          href="https://www.instagram.com/fantasyflip99"
          target="_blank"
        >
          <v-icon :large="$vuetify.breakpoint.smAndUp" color="primary">
            mdi-instagram
          </v-icon>
        </v-btn>
        <div class="px-2 hidden-xs-only"></div>
        <v-btn icon href="https://github.com/fantasyflip" target="_blank">
          <v-icon :large="$vuetify.breakpoint.smAndUp" color="primary">
            mdi-github
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-app-bar>
</template>
<script>
export default {
  name: "navBar",
  computed: {
    titleClass: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-h5 font-weight-bold pl-2";
        case "sm":
          return "text-h4 font-weight-bold pl-2";
        case "md":
          return "text-h4 font-weight-bold pl-2";
        case "lg":
          return "text-h4 font-weight-bold pl-2";
        case "xl":
          return "text-h4 font-weight-bold pl-2";
        default:
          return "text-h4 font-weight-bold pl-2";
      }
    },
  },
};
</script>
<style scoped></style>
