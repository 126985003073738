<template>
  <v-app class="app">
    <v-main>
      <v-container fluid class="ma-0 pa-0">
        <navBar />
        <router-view class="mt-16" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import navBar from "./components/navBar.vue";
export default {
  name: "App",
  metaInfo() {
    return {
      // all titles will be injected into this template
      titleTemplate: "%s | FantasyFlip",
    };
  },
  components: {
    navBar,
  },
};
</script>

<style>
body {
  background-color: var(--v-background-base);
}
.app {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

/* width / */
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* / Track / */
::-webkit-scrollbar-track {
  background: var(--v-primary-base);
}

/* / Handle / */
::-webkit-scrollbar-thumb {
  background: var(--v-background-base);
}

/* / Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--v-secondary-base);
}
</style>
